<template>
  <b-container>
    <b-row>
      <b-col sm="auto" md="10" lg="8" xl="6">
        <h1>Medlemmar</h1>
        <MembersStats :members="members" />
        <MembersList :members="members" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import { db } from '@/firebaseConfig.js'
  import { collection, onSnapshot, query } from 'firebase/firestore'
  import MembersList from '@/components/MembersList.vue'
  import MembersStats from '@/components/MembersStats.vue'

  export default {
    mounted() {
      const q = query(collection(db, 'members'))
      this.unsubscribe = onSnapshot(q, (querySnapshot) => {
        const membs = []
        querySnapshot.forEach((doc) => {
          membs.push({ id: doc.id, ...doc.data() })
        })
        this.members = membs
      })
    },
    destroyed() {
      this.unsubscribe()
    },
    data() {
      return {
        members: [],
        unsubscribe: null
      }
    },
    components: { MembersList, MembersStats }
  }

</script>
